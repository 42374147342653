import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "svFooter",
  "leftColumn": {
    "title": "ZIMPLY INNOVATION",
    "city": "Stockholm",
    "adress": "Artillerigatan 42",
    "postCode": "114 45 Stockholm",
    "city2": "Göteborg",
    "adress2": "Jons väg 21",
    "postCode2": "433 75 Jonsered"
  },
  "middleColumn": {
    "title": "KONTAKT",
    "line1": "Zimply Innovation",
    "line2": "08 122 09 339",
    "link": "hello@zimply.ai"
  },
  "rightColumn": {
    "title": "SOCIALT",
    "fb": "/img/fb.webp",
    "fbPath": "https://www.facebook.com/zimplyinnovation",
    "linkedinPath": "https://www.linkedin.com/company/zimply-innovation/",
    "instaPath": "https://www.instagram.com/zimplyinnovation/",
    "insta": "/img/instagram.webp",
    "linkedIn": "/img/linkedin.webp"
  },
  "logo": "/img/zimply-text-white.webp"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      